import { reactTrpc } from 'utilities/react-trpc';
import React from 'react';
import { Anchor, Alert, Button, Stack } from '@good/ui/core';
import { daysBetween } from 'utilities/date-utils';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import PermissionUtils from 'utilities/permission-utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ExternalLink } from '@good/icons';

export const SubscriptionStatusBanner = () => {
  const flags = useFlags();
  const portalUser = usePortalUser();
  const hasSubscriptionBillingPermission = PermissionUtils.validatePermission(
    'ViewSubscriptionBilling',
    portalUser.permissions.permissionRoles,
  );

  const { data: subscriptionPaymentDetails } = reactTrpc.serviceProvider.subscription.paymentStatus.useQuery();
  if (!subscriptionPaymentDetails) return null;
  const isPastDueOrUnpaid =
    subscriptionPaymentDetails.subscriptionStatus === 'past_due' ||
    subscriptionPaymentDetails.subscriptionStatus === 'unpaid';

  const daysPastDue = Math.floor(
    daysBetween(
      new Date(
        (subscriptionPaymentDetails.latestInvoice?.due_date ??
          subscriptionPaymentDetails.latestInvoice?.created ??
          Date.now() / 1000) * 1000,
      ),
      new Date(),
    ),
  );

  if (!flags['newSubscriptionManagement']) return null;

  const banner =
    hasSubscriptionBillingPermission && isPastDueOrUnpaid && daysPastDue < 7 ? (
      <PaymentFailedWarning invoiceUrl={subscriptionPaymentDetails.latestInvoice.hosted_invoice_url} />
    ) : hasSubscriptionBillingPermission && isPastDueOrUnpaid && daysPastDue >= 7 && daysPastDue < 14 ? (
      <SevenDaysOverdueWarning
        daysPastDue={daysPastDue}
        invoiceUrl={subscriptionPaymentDetails.latestInvoice?.hosted_invoice_url ?? 'https://'}
      />
    ) : isPastDueOrUnpaid && daysPastDue >= 14 && daysPastDue < 21 ? (
      <FourteenDaysUnpaidWarning
        daysPastDue={daysPastDue}
        invoiceUrl={subscriptionPaymentDetails.latestInvoice?.hosted_invoice_url ?? 'https://'}
      />
    ) : isPastDueOrUnpaid && daysPastDue >= 21 && daysPastDue < 30 ? (
      <TwentyOneDaysUnpaidWarning
        daysPastDue={daysPastDue}
        invoiceUrl={subscriptionPaymentDetails.latestInvoice?.hosted_invoice_url ?? 'https://'}
      />
    ) : isPastDueOrUnpaid && daysPastDue >= 30 ? (
      <OverThirtyDaysUnpaidWarning />
    ) : subscriptionPaymentDetails.subscriptionStatus === 'canceled' ? (
      <SubscriptionCanceledError />
    ) : null;

  return banner ? <Stack>{banner}</Stack> : null;
};

const RetryPaymentButton = ({ invoiceUrl }: { invoiceUrl?: string }) => {
  const portalUser = usePortalUser();
  const showRetryButton = PermissionUtils.validatePermission(
    'AccessRetryPaymentLink',
    portalUser.permissions.permissionRoles,
  );

  return showRetryButton && invoiceUrl ? (
    <Button component={Anchor} leftSection={<ExternalLink />} href={invoiceUrl} target='_blank'>
      Retry payment
    </Button>
  ) : null;
};

const PaymentFailedWarning = ({ invoiceUrl }: { invoiceUrl?: string }) => (
  <Alert color='yellow' radius={0} px='lg' py='xs' title='Payment failed.'>
    <Stack align='start'>
      <div>
        Your payment has failed. Please rectify the issue immediately to avoid the suspension of your account. If you
        need help please contact our customer support team via our{' '}
        <Anchor size='sm' href='/support'>
          help center
        </Anchor>
        , or at{' '}
        <Anchor size='sm' href='mailto:support@goodhuman.me'>
          support@goodhuman.me
        </Anchor>
        .
      </div>
      <RetryPaymentButton invoiceUrl={invoiceUrl} />
    </Stack>
  </Alert>
);

const SevenDaysOverdueWarning = ({ daysPastDue, invoiceUrl }: { daysPastDue: number; invoiceUrl?: string }) => (
  <Alert color='yellow' radius={0} px='lg' py='xs' title='Payment overdue.'>
    <Stack align='start'>
      <div>
        Your payment is {daysPastDue} days overdue. Please rectify the issue immediately to avoid the suspension of your
        account. If you need help please contact our customer support team via our{' '}
        <Anchor size='sm' href='/support'>
          help center
        </Anchor>
        , or at{' '}
        <Anchor size='sm' href='mailto:support@goodhuman.me'>
          support@goodhuman.me
        </Anchor>
        .
      </div>
      <RetryPaymentButton invoiceUrl={invoiceUrl} />
    </Stack>
  </Alert>
);

const FourteenDaysUnpaidWarning = ({ daysPastDue, invoiceUrl }: { daysPastDue: number; invoiceUrl?: string }) => (
  <Alert color='red' radius={0} px='lg' py='xs' title='Your subscription is unpaid.'>
    <Stack align='start'>
      <div>
        Your payment is {daysPastDue} days in arrears. Please rectify the issue immediately to avoid the suspension of
        this account. If you need help please contact our customer support team via our{' '}
        <Anchor size='sm' href='/support'>
          help center
        </Anchor>{' '}
        or at{' '}
        <Anchor size='sm' href='mailto:support@goodhuman.me'>
          support@goodhuman.me
        </Anchor>
        .
      </div>
      <RetryPaymentButton invoiceUrl={invoiceUrl} />
    </Stack>
  </Alert>
);

const TwentyOneDaysUnpaidWarning = ({ daysPastDue, invoiceUrl }: { daysPastDue: number; invoiceUrl?: string }) => (
  <Alert color='red' radius={0} px='lg' py='xs' title='Your subscription is unpaid.'>
    <Stack align='start'>
      <div>
        We will be removing access for all users of the system in {30 - daysPastDue} days if payment isn&apos;t received
        prior. If you need help please contact our customer support team via our{' '}
        <Anchor size='sm' href='/support'>
          help center
        </Anchor>{' '}
        or at{' '}
        <Anchor size='sm' href='mailto:support@goodhuman.me'>
          support@goodhuman.me
        </Anchor>
        .
      </div>
      <RetryPaymentButton invoiceUrl={invoiceUrl} />
    </Stack>
  </Alert>
);

const OverThirtyDaysUnpaidWarning = () => (
  <Alert color='red' radius={0} px='lg' py='xs' title='Your subscription is unpaid.'>
    <Stack align='start'>
      <div>
        Your account is scheduled to be suspended due to a failed payment. If you need help please contact our customer
        support team via our{' '}
        <Anchor size='sm' href='/support'>
          help center
        </Anchor>{' '}
        or at{' '}
        <Anchor size='sm' href='mailto:support@goodhuman.me'>
          support@goodhuman.me
        </Anchor>
        .
      </div>
    </Stack>
  </Alert>
);

const SubscriptionCanceledError = () => (
  <Alert color='red' radius={0} px='lg' py='xs' title='Account suspended.'>
    <Stack align='start'>
      <div>
        We&apos;re so sorry. Your account has been suspended due to a failed payment. Don&apos;t fret, you haven&apos;t
        lost your data and can still access your account once payment is made. Please update your payment details to
        ensure payment can be made. If you are unable to make payment or need assistance please contact our customer
        support team at{' '}
        <Anchor size='sm' href='mailto:support@goodhuman.me'>
          support@goodhuman.me
        </Anchor>
        . We&apos;re here to help in any way we can.
      </div>
    </Stack>
  </Alert>
);
